import { graphql } from 'gatsby'
import queryString from 'query-string'
import React, { useContext, useEffect, useState } from 'react'

import Layout from '../components/Layout'
import Reviews from '../components/Reviews'
import StaticHeader from '../components/StaticHeader'
import SEO from '../components/seo'

const ShopPage = ({ data, location }) => {
  const [searchParams, setSearchParams] = useState()
  useEffect(() => {
    const search = queryString.parse(location.search)
    const terms = ['travel-bags', 'luggage', 'accessories']
    const paramKeys = Object.keys(search)
    paramKeys.map((r) => {
      const index = terms.findIndex((val) => r === val)
      if (index !== -1) {
        setSearchParams(index)
      }
    })
  }, [location.search])

  return (
    <Layout location="shop">
      <StaticHeader title="All Reviews" />
      <br />
      <Reviews productId="6546329534521"></Reviews>
      <SEO
        title={`July Luggage Review`}
        description={
          'Read real customer reviews of July luggage, including the July Carry On and Checked suitcases.'
        }
      />
    </Layout>
  )
}

export default ShopPage

export const query = graphql`
  {
    HeroImage: file(relativePath: { eq: "bundle.jpeg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
  }
`
